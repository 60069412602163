import React, { useState } from 'react';

const Disclaimer = ({ onAgree }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleAgree = () => {
    setIsOpen(false);
    onAgree();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg max-w-2xl">
        <h2 className="text-2xl font-bold mb-4">Disclaimer</h2>
        <p className="mb-4">
          The stock search results provided on this website are for informational purposes only and do not constitute financial, investment, or trading advice. The inclusion of a stock in a search result based on a theme or set of keywords does not represent a recommendation to buy, sell, or hold any security. All users are encouraged to conduct their own independent research and consult with a licensed financial advisor before making any investment decisions.
        </p>
        <p className="mb-4">
          While we strive to ensure that the data presented is accurate and up to date, we cannot guarantee the completeness, reliability, or accuracy of the information. The data provided, including but not limited to company details, stock prices, and performance metrics, may contain inaccuracies or be subject to change without notice. The website and its operators are not liable for any losses or damages arising from the use of this information.
        </p>
        <p className="mb-6">
          By using this website, you acknowledge and agree to the terms of this disclaimer.
        </p>
        <button
          onClick={handleAgree}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          I Agree
        </button>
      </div>
    </div>
  );
};

export default Disclaimer;
