// renderUtils.js
export const renderProductsAndServices = (data) => {
  if (!data) {
    console.error('Data is undefined or null');
    return null;
  }

  if (typeof data === 'string') {
    try {
      data = JSON.parse(data);
    } catch (error) {
      console.error('Failed to parse JSON:', error);
      return null;
    }
  }

  if (typeof data !== 'object' || data === null) {
    console.error('Data is not an object after parsing');
    return null;
  }

  console.log('Data before mapping:', data);

  const renderItems = (items) => {
    if (Array.isArray(items)) {
      return items.map((item, index) => {
        if (item === null) {
          console.warn(`Item ${index} is null`);
          return null;
        }
        console.log(`Item ${index}:`, item);
        return <li key={index}>{typeof item === 'object' ? renderItems(item) : item}</li>;
      });
    } else if (typeof items === 'object') {
      return (
        <ul className="list-disc ml-8">
          {Object.entries(items).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {typeof value === 'object' ? renderItems(value) : value}
            </li>
          ))}
        </ul>
      );
    } else {
      return <li>{items}</li>;
    }
  };

  return Object.entries(data).map(([category, items]) => {
    console.log(`Category: ${category}, Items:`, items);
    if (items === null) {
      console.warn(`Items for category "${category}" are null`);
      return null;
    }
    return (
      <div key={category} className="mb-4">
        <h4 className="font-bold text-lg">{category}</h4>
        <ul className="list-disc ml-8">
          {renderItems(items)}
        </ul>
      </div>
    );
  }).filter(Boolean); // Remove any null entries
}