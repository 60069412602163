import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, Label } from 'recharts';
import axios from 'axios';

export default function StockPriceGraph({ company }) {
  const [stockData, setStockData] = useState([]);
  const [indexData, setIndexData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [timeRange, setTimeRange] = useState('1Y');
  const [performanceData, setPerformanceData] = useState({});

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        setLoading(true);
        const apiKey = "5VsF080UidTh1nQomwkcgnQjxIvkWktP";
        const today = new Date();
        const fiveYearsAgo = new Date(today.getFullYear() - 5, today.getMonth(), today.getDate());
        const fromDate = fiveYearsAgo.toISOString().split('T')[0];
        const toDate = today.toISOString().split('T')[0];
        
        console.log('Fetching stock data from Financial Modeling Prep API...');
        let ticker = company.ticker;
        if (company.geography === 'Japan') {
          ticker = `${company.ticker}.T`;
        } else if (company.geography === 'Korea') {
          ticker = ticker.padStart(6, '0').toString();
          ticker = `${ticker}.KS`;
        }
        console.log(`URL: https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${fromDate}&to=${toDate}&apikey=${apiKey}`);

        const stockResponse = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${ticker}?from=${fromDate}&to=${toDate}&apikey=${apiKey}`);
        
        console.log('Stock response status:', stockResponse.status);
        console.log('Stock response data:', stockResponse.data);

        if (stockResponse.status !== 200) {
          throw new Error('Failed to fetch stock data');
        }

        if (!stockResponse.data.historical || stockResponse.data.historical.length === 0) {
          throw new Error('No stock data available');
        }

        const formattedStockData = stockResponse.data.historical.map(item => ({
          date: new Date(item.date),
          price: item.close
        })).reverse();

        console.log('Formatted stock data:', formattedStockData);

        setStockData(formattedStockData);

        // Fetch index ETF data
        let indexETFTicker;
        switch(company.geography) {
          case 'United States':
            indexETFTicker = 'SPY'; // SPDR S&P 500 ETF Trust
            break;
          case 'Japan':
            indexETFTicker = 'EWJ'; // iShares MSCI Japan ETF
            break;
          case 'Korea':
            indexETFTicker = 'EWY'; // iShares MSCI South Korea ETF
            break;
          default:
            throw new Error('Unsupported geography');
        }

        const indexResponse = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${indexETFTicker}?from=${fromDate}&to=${toDate}&apikey=${apiKey}`);

        if (indexResponse.status !== 200) {
          throw new Error('Failed to fetch index ETF data');
        }

        const formattedIndexData = indexResponse.data.historical.map(item => ({
          date: new Date(item.date),
          price: item.close
        })).reverse();

        setIndexData(formattedIndexData);

        calculatePerformance(formattedStockData, formattedIndexData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    const calculatePerformance = (stockData, indexData) => {
      const now = new Date();
      const ytdDate = new Date(now.getFullYear(), 0, 1);
      const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      const threeYearsAgo = new Date(now.getFullYear() - 3, now.getMonth(), now.getDate());
      const fiveYearsAgo = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());

      const filterData = (data, startDate) => data.filter(item => item.date >= startDate);

      const calculatePerformanceForPeriod = (stockData, indexData, startDate) => {
        const filteredStockData = filterData(stockData, startDate);
        if (filteredStockData.length === 0) return { stock: 'N/A', index: 'N/A' };
        
        const stockStartDate = filteredStockData[0].date;
        const filteredIndexData = indexData.filter(item => item.date >= stockStartDate);
        
        const baseStockPrice = filteredStockData[0].price;
        const currentStockPrice = filteredStockData[filteredStockData.length - 1].price;
        const stockPerformance = ((currentStockPrice / baseStockPrice - 1) * 100).toFixed(2);
        
        let indexPerformance = 'N/A';
        if (filteredIndexData.length > 0) {
          const baseIndexPrice = filteredIndexData[0].price;
          const currentIndexPrice = filteredIndexData[filteredIndexData.length - 1].price;
          indexPerformance = ((currentIndexPrice / baseIndexPrice - 1) * 100).toFixed(2);
        }
        
        return { stock: stockPerformance, index: indexPerformance };
      };

      setPerformanceData({
        YTD: calculatePerformanceForPeriod(stockData, indexData, ytdDate),
        '1Y': calculatePerformanceForPeriod(stockData, indexData, oneYearAgo),
        '3Y': calculatePerformanceForPeriod(stockData, indexData, threeYearsAgo),
        '5Y': calculatePerformanceForPeriod(stockData, indexData, fiveYearsAgo),
      });
    };

    console.log('Company:', company);
    console.log('Ticker:', company.ticker);
    if (company && company.ticker) {
      fetchStockData();
    } else {
      setError('No ticker available for this company');
      setLoading(false);
    }
  }, [company]);

  const getFilteredAndIndexedData = () => {
    const now = new Date();
    const ytdDate = new Date(now.getFullYear(), 0, 1);
    const oneYearAgo = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
    const threeYearsAgo = new Date(now.getFullYear() - 3, now.getMonth(), now.getDate());
    const fiveYearsAgo = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());

    let startDate;
    switch(timeRange) {
      case 'YTD':
        startDate = ytdDate;
        break;
      case '1Y':
        startDate = oneYearAgo;
        break;
      case '3Y':
        startDate = threeYearsAgo;
        break;
      case '5Y':
        startDate = fiveYearsAgo;
        break;
      default:
        startDate = fiveYearsAgo;
    }

    const filteredStockData = stockData.filter(item => item.date >= startDate);
    if (filteredStockData.length === 0) return [];

    const stockStartDate = filteredStockData[0].date;
    const filteredIndexData = indexData.filter(item => item.date >= stockStartDate);

    const baseStockPrice = filteredStockData[0].price;
    const baseIndexPrice = filteredIndexData[0]?.price;

    // Ensure we have matching data points for stock and index
    const minLength = Math.min(filteredStockData.length, filteredIndexData.length);
    
    return filteredStockData.slice(0, minLength).map((item, index) => {
      const indexItem = filteredIndexData[index];
      
      // Check if we have valid data for both stock and index
      if (!item || !item.price) {
        console.warn(`Invalid stock data at index ${index}:`, item);
        return null;
      }

      const stockPrice = Number(((item.price / baseStockPrice - 1) * 100).toFixed(2));
      let indexPrice = null;
      if (indexItem && indexItem.price) {
        indexPrice = Number(((indexItem.price / baseIndexPrice - 1) * 100).toFixed(2));
      }

      return {
        date: item.date,
        stockPrice: stockPrice,
        indexPrice: indexPrice
      };
    }).filter(Boolean); // Remove any null entries
  };

  if (loading) return <div>Loading stock data...</div>;
  if (error) return <div>Error: {error}</div>;

  const formatYAxis = (value) => `${value.toFixed(0)}%`;
  const formatXAxis = (date) => {
    const d = new Date(date);
    return d.toLocaleString('default', { month: 'short', year: '2-digit' });
  };

  const getTicks = (data) => {
    const numTicks = 6;
    const step = Math.floor(data.length / (numTicks - 1));
    return data.filter((_, index) => index % step === 0 || index === data.length - 1).map(item => item.date);
  };

  const filteredAndIndexedData = getFilteredAndIndexedData();

  const getIndexName = () => {
    switch(company.geography) {
      case 'United States':
        return 'S&P 500 ETF (SPY)';
      case 'Japan':
        return 'MSCI Japan ETF (EWJ)';
      case 'Korea':
        return 'MSCI South Korea ETF (EWY)';
      default:
        return 'Index ETF';
    }
  };

  return (
    <div className="stock-price-graph">
      <div className="flex justify-between items-center mb-6">
        <div className="time-range-buttons">
          {['YTD', '1Y', '3Y', '5Y'].map((range) => (
            <button
              key={range}
              onClick={() => setTimeRange(range)}
              className={`mr-2 px-3 py-1 text-sm rounded ${timeRange === range ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {range}
            </button>
          ))}
        </div>
        <div className="performance-table">
          <table className="border-collapse text-sm">
            <thead>
              <tr>
                <th className="border p-1 text-center"></th>
                <th className="border p-1 text-center">YTD</th>
                <th className="border p-1 text-center">1Y</th>
                <th className="border p-1 text-center">3Y</th>
                <th className="border p-1 text-center">5Y</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border p-1 text-left">{company.company_name}</td>
                <td className="border p-1 text-center">{performanceData.YTD?.stock}%</td>
                <td className="border p-1 text-center">{performanceData['1Y']?.stock}%</td>
                <td className="border p-1 text-center">{performanceData['3Y']?.stock}%</td>
                <td className="border p-1 text-center">{performanceData['5Y']?.stock}%</td>
              </tr>
              <tr>
                <td className="border p-1 text-left">{getIndexName()}</td>
                <td className="border p-1 text-center">{performanceData.YTD?.index}%</td>
                <td className="border p-1 text-center">{performanceData['1Y']?.index}%</td>
                <td className="border p-1 text-center">{performanceData['3Y']?.index}%</td>
                <td className="border p-1 text-center">{performanceData['5Y']?.index}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {filteredAndIndexedData.length > 0 ? (
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={filteredAndIndexedData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="date" 
              tickFormatter={formatXAxis} 
              ticks={getTicks(filteredAndIndexedData)}
              padding={{ right: 30 }}
            />
            <YAxis tickFormatter={formatYAxis} />
            <Tooltip 
              formatter={(value, name) => {
                const label = name === `${company.company_name} Stock Price` ? company.company_name : getIndexName();
                return [`${value.toFixed(2)}%`, `${label} Indexed Price`];
              }} 
              labelFormatter={(label) => new Date(label).toLocaleDateString()} 
            />
            <Legend />
            <Line type="monotone" dataKey="stockPrice" name={`${company.company_name} Stock Price`} stroke="#8884d8" dot={false}>
              <Label
                content={({ value, x, y }) => {
                  const lastPoint = filteredAndIndexedData[filteredAndIndexedData.length - 1];
                  return (
                    <text x={x} y={y} dy={-10} fill="#8884d8" fontSize={12} textAnchor="middle">
                      {`${lastPoint.stockPrice.toFixed(2)}%`}
                    </text>
                  );
                }}
                position="top"
              />
            </Line>
            <Line type="monotone" dataKey="indexPrice" name={`${getIndexName()} Price`} stroke="#82ca9d" dot={false}>
              <Label
                content={({ value, x, y }) => {
                  const lastPoint = filteredAndIndexedData[filteredAndIndexedData.length - 1];
                  return lastPoint.indexPrice !== null ? (
                    <text x={x} y={y} dy={-10} fill="#82ca9d" fontSize={12} textAnchor="middle">
                      {`${lastPoint.indexPrice.toFixed(2)}%`}
                    </text>
                  ) : null;
                }}
                position="top"
              />
            </Line>
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <div>No stock data available</div>
      )}
    </div>
  );
}