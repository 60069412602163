export const translations = {
    en: {
      title: "project borderless",
      subtitle: "Search 10,000+ detailed public company profiles from 3 different countries.",
      loading: "Loading...",
      error: "An error occurred while searching. Please try again.",
      noResults: "No companies found.",
      home: "Home",
      blog: "Blog",
      contact: "Contact",
      searchPlaceholder: "Type in a query (e.g. quantum computing, gene therapy, military drones)",
      industries: "Industries",
      companyName: "Company Name",
      description: "Description",
      website: "Website",
      founded: "Founded",
      logo: "Logo",
      ticker: "Ticker",
      latestNews: 'News',
      source: 'Source',
      date: 'Date',
      relatedCompanies: 'Related Companies',
      articles: 'Articles',
      newsError: 'Error loading news. Please try again later.',
      geography: "Geography",
      exchange: "Exchange",
      mission: "Mission",
    },
    es: {
      title: "¿quién es esa acción?",
      subtitle: "Proyecto Sinfronteras v1.0",
      loading: "Cargando...",
      error: "Ocurrió un error durante la búsqueda. Por favor, inténtelo de nuevo.",
      noResults: "No se encontraron empresas.",
      home: "Inicio",
      blog: "Blog",
      contact: "Contacto",
      searchPlaceholder: "Escribe una pregunta o un comando '/' para comenzar",
      industries: "Industrias",
      companyName: "Nombre de la Empresa",
      description: "Descripción",
      website: "Sitio Web",
      founded: "Fundada",
      logo: "Logo",
      ticker: "Símbolo",
      geography: "Geografía",
      exchange: "Intercambio"
    },
    ko: {
      title: "넌 무슨 주식이야?",
      subtitle: "3개국, 10,000개 이상 기업 중, 원하는 주식을 단번에 찾아 보세요",
      loading: "로딩 중...",
      error: "검색 중 오류가 발생했습니다. 다시 시도해 주세요.",
      noResults: "회사를 찾을 수 없습니다.",
      home: "홈",
      blog: "블로그",
      contact: "연락처",
      searchPlaceholder: "질문을 입력하거나 '/' 명령어로 시작하세요",
      industries: "산업",
      companyName: "회사명",
      description: "설명",
      website: "웹사이트",
      founded: "설립년도",
      logo: "로고",
      ticker: "티커",
      geography: "지리",
      exchange: "거래소"
    },
    ja: {
      title: "だれだ？",
      subtitle: "プロジェクト・ボーダーレス v1.0",
      loading: "読み込み中...",
      error: "検索中にエラーが発生しました。もう一度お試しください。",
      noResults: "企業が見つかりませんでした。",
      home: "ホーム",
      blog: "ブログ",
      contact: "お問い合わせ",
      searchPlaceholder: "質問を入力するか、'/'コマンドで開始してください",
      industries: "業界",
      companyName: "会社名",
      description: "説明",
      website: "ウェブサイト",
      founded: "設立年",
      logo: "ロゴ",
      ticker: "ティッカー"
    }
  };