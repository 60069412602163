import Typesense from 'typesense';
import OpenAI from 'openai';
import axios from 'axios';

export const typesenseClient = new Typesense.Client({
  nodes: [{ host: process.env.REACT_APP_TYPESENSE_HOST, port: '443', protocol: 'https' }],
  apiKey: process.env.REACT_APP_TYPESENSE_API_KEY,
  connectionTimeoutSeconds: 5,
  searchTimeoutSeconds: 10
});

// export const extractIndustriesAndSectors = async (keywords) => {
//   try {
//     const prompt = `
//     Please classify the following keywords: ${keywords.join(', ')} into at least 1, but not more than 3, of the following [SECTORS], separated by commas. Return as few sectors as possible. Return only the sector names and nothing else. If there are no perfect matches, then return the closest matches. Again, do not return anything other than the relevant sector names.
    
//     [SECTORS]:
//     Aerospace & Defense
//     Agriculture
//     Airlines
//     Asset Management
//     Automobiles
//     Banks
//     Biopharmaceuticals
//     Chemicals
//     Construction
//     Consumer Discretionary
//     Consumer Electronics
//     Consumer Staples
//     Consumer Retail
//     E-Commerce
//     Education
//     Fintech
//     Health Insurance
//     Hospitals
//     Life Insurance
//     Machinery
//     Media and Entertainment
//     Medical Devices
//     Metals & Mining
//     Oil & Gas
//     Professional Services
//     Property & Casualty Insurance
//     Real Estate & Gaming & Hospitality
//     REITs
//     Restaurants
//     Semiconductors
//     Social Media
//     Software
//     Utilities
//     Telecommunications
//     Transportation & Logistics
//     `;

//     const response = await openai.chat.completions.create({
//       model: "gpt-3.5-turbo",
//       messages: [
//         { role: "system", content: "You are a helpful assistant that extracts relevant industries and sectors from keywords." },
//         { role: "user", content: prompt }
//       ],
//       max_tokens: 100
//     });

//     const extractedText = response.choices[0].message.content;

//     console.log('Extracted text:', extractedText);

//     // Parse the extracted text to get an array of industries and sectors
//     const industriesAndSectors = extractedText.split(',').map(item => item.trim());
//     return industriesAndSectors;
//   } catch (error) {
//     console.error('Error extracting industries and sectors:', error);
//     return [];
//   }
// };

const openai = new OpenAI({ apiKey: process.env.REACT_APP_OPENAI_API_KEY, dangerouslyAllowBrowser: true });

export const searchByTicker = async (query, geographyFilter) => {
  try {
    let filterByGeography = '';
    if (geographyFilter === 'us') {
      filterByGeography = 'geography:United States';
    } else if (geographyFilter === 'kr') {
      filterByGeography = 'geography:Korea';
    } else if (geographyFilter === 'jp') {
      filterByGeography = 'geography:Japan';
    }

    const searchParameters = {
      q: query,
      query_by: 'ticker',
      filter_by: filterByGeography,
      per_page: 1
    };

    const searchResults = await typesenseClient
      .collections('companies_v9')
      .documents()
      .search(searchParameters);

    const results = searchResults.hits.map(hit => hit.document);

    // Wipe query value
    query = '';

    return results;
  } catch (error) {
    console.error('Error searching by ticker:', error);
    return [];
  }
};

export const searchByIndustry = async (query, geographyFilter) => {
  try {
    let filterByGeography = '';
    if (geographyFilter === 'us') {
      filterByGeography = 'geography:United States';
    } else if (geographyFilter === 'kr') {
      filterByGeography = 'geography:Korea';
    } else if (geographyFilter === 'jp') {
      filterByGeography = 'geography:Japan';
    }

    const searchParameters = {
      q: '*',
      filter_by: `industries:${query}${filterByGeography ? ' && ' + filterByGeography : ''}`,
      sort_by: 'lfy_revenue:desc',
      per_page: 30
    };

    const searchResults = await typesenseClient
      .collections('companies_v9')
      .documents()
      .search(searchParameters);

    const results = searchResults.hits.map(hit => hit.document);

    // Wipe query value
    query = '';

    return results;
  } catch (error) {
    console.error('Error searching by industry:', error);
    return [];
  }
};

export const searchByName = async (query, geographyFilter) => {
  try {
    let filterByGeography = '';
    if (geographyFilter === 'us') {
      filterByGeography = 'geography:United States';
    } else if (geographyFilter === 'kr') {
      filterByGeography = 'geography:Korea';
    } else if (geographyFilter === 'jp') {
      filterByGeography = 'geography:Japan';
    }

    const searchParameters = {
      q: query,
      query_by: 'company_name',
      ...(filterByGeography ? { filter_by: filterByGeography } : {}),
      per_page: 1
    };

    const searchResults = await typesenseClient
      .collections('companies_v9')
      .documents()
      .search(searchParameters);

    const results = searchResults.hits.map(hit => hit.document);

    // Wipe query value
    query = '';

    return results;
  } catch (error) {
    console.error('Error searching by name:', error);
    return [];
    
  }
};


export const searchCompanies = async (query, geographyFilter) => {
    console.log('adjkfa');
    console.log('query')
    console.log()
    try {      
      let filterByGeography;
      if (geographyFilter === 'us') {
        filterByGeography = 'United States';
      } else if (geographyFilter === 'kr') {
        filterByGeography = 'Korea';
      } else if (geographyFilter === 'jp') {
        filterByGeography = 'Japan';
      } else {
        filterByGeography = '*';
      }

      let filterBy = [];
      if (geographyFilter === 'us' || geographyFilter === 'kr' || geographyFilter === 'jp') {
        filterBy.push(`geography:${filterByGeography}`);
      }

      const searchParameters = {
        q: query,
        query_by: ['themes','products','geography','category1','category2','category3','embedding1','industries','company_name'],
        sort_by: '_text_match:desc',
        prefix: false,
        per_page: 30,
        ...(filterBy.length > 0 ? { filter_by: filterBy.join(' && ') } : {}),
        query_by_weights: [12,12,12,6,6,6,6,12,1],
        timeout_ms: 15000
      };

      const searchResults = await typesenseClient
        .collections('companies_v9')
        .documents()
        .search(searchParameters);

      const results = searchResults.hits
        .filter(hit => hit.hybrid_search_info.rank_fusion_score > 0.01)
        .map(hit => ({
          company_name: hit.document.company_name,
          description: hit.document.description,
          lfy_revenue: hit.document.lfy_revenue,
          industries: hit.document.industries,
          sectors: hit.document.sectors,
          products: hit.document.products,
          embedding: hit.document.embedding,
          geography: hit.document.geography,
          id: hit.document.id,
          ticker: hit.document.ticker,
          exchange: hit.document.exchange,
          rank_fusion_score: hit.hybrid_search_info.rank_fusion_score
        }));

      // Wipe query value
      query = '';

      return results;
    } catch (error) {
      console.error('Error searching companies:', error);
      return [];
    }
};


export const analyzeImpact = async (query) => {
  // Implement impact analysis logic here
  // After analysis, wipe query value
  query = '';
};

export const getSuggestions = async (query, command, geographyFilter) => {
  try {
    let searchParameters;
    let filterByGeography = '';
    if (geographyFilter === 'us') {
      filterByGeography = 'geography:United States';
    } else if (geographyFilter === 'kr') {
      filterByGeography = 'geography:Korea';
    } else if (geographyFilter === 'jp') {
      filterByGeography = 'geography:Japan';
    }

    switch (command) {
      case '/ticker':
        searchParameters = {
          q: query,
          query_by: 'ticker',
          per_page: 1
        };
        break;
      case '/name':
        searchParameters = {
          q: query,
          query_by: 'company_name',
          per_page: 1
        };
        break;
      case '/industries':
        searchParameters = {
          q: '*',
          filter_by: `industries:${query}${filterByGeography ? ' && ' + filterByGeography : ''}`,
          sort_by: 'lfy_revenue:desc',
          per_page: 30
        };
        break;
      default:
        return [];
    }

    const searchResults = await typesenseClient
      .collections('companies_v9')
      .documents()
      .search(searchParameters);

    let suggestions;
    if (command === '/industries') {
      suggestions = searchResults.grouped_hits
        .map(group => group.hits[0].document.industries)
        .flat()
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort();
    } else {
      suggestions = searchResults.hits.map(hit => {
        switch (command) {
          case '/ticker':
            return hit.document.ticker;
          case '/name':
            return hit.document.company_name;
          default:
            return '';
        }
      });
    }

    // Wipe query value
    query = '';

    return suggestions;
  } catch (error) {
    console.error('Error getting suggestions:', error);
    return [];
  }
};