import React, { useState } from 'react';

export default function SearchBar({ query, setQuery, handleSearch, placeholder }) {
  const [showPlaceholder, setShowPlaceholder] = useState(true);

  const handleSearchWrapper = (e) => {
    e.preventDefault();
    handleSearch(query);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearchWrapper(e);
    }
  };

  const handleFocus = () => {
    setShowPlaceholder(false);
  };

  const handleBlur = () => {
    if (!query) {
      setShowPlaceholder(true);
    }
  };

  return (
    <div className="relative flex items-center w-full">
      <input
        type="text"
        className="px-6 py-2 w-full border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-300 focus:border-gray-300 text-xl pr-12 transition-all duration-200"
        placeholder={showPlaceholder ? placeholder : ''}
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <button
        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black text-white rounded-lg p-2 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-200"
        onClick={handleSearchWrapper}
        aria-label="Search"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
        </svg>
      </button>
    </div>
  );
}