import React from 'react';
import { translations } from '../translations';
import { Link } from 'react-router-dom';

const Contact = () => {
  const t = (key) => translations.en[key]; // Assuming English as default language

  return (
    <div className="min-h-screen bg-black flex flex-col" style={{ fontFamily: "'Poppins', sans-serif" }}>
      {/* Header Component */}
      <div className="bg-blue-400 text-white py-2 text-center cursor-pointer">
        <a href="/mission" className="hover:underline text-sm sm:text-base">
          Stockshop is in public beta mode. Click here for our mission and our roadmap for the next year.
        </a>
      </div>

      <div className="bg-black">
        <header className="py-2 container mx-auto px-4">
          <div className="flex items-center justify-between">
            <h1 className="text-xl sm:text-2xl text-white">stockshop 🛍️</h1>
            <nav>
              <ul className="flex space-x-6">
                <li><a href="/" className="text-sm sm:text-base text-white">{t('home')}</a></li>
                <li><Link to="/mission" className="text-sm sm:text-base text-white">{t('mission')}</Link></li>
                <li><a href="/contact" className="text-sm sm:text-base text-white">{t('contact')}</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <div className="container mx-auto px-4 py-1">
          <h1 className="text-3xl sm:text-4xl font-bold text-center mb-1 text-white">{t('contact.title')}</h1>
        </div>
      </div>

      <div className="flex-grow py-4 flex flex-col justify-start sm:py-8">
        <div className="container mx-auto px-4">
          <div className="relative px-4 py-6 bg-black sm:rounded-3xl sm:p-8">
            <div className="max-w-9xl mx-auto">
              <div className="flex flex-col space-y-8">
                <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                  <h2 className="text-xl sm:text-2xl font-semibold mb-4">Get in Touch</h2>
                  <p className="text-base mb-4">
                    We'd love to hear from you. Whether you have a question about our services, 
                    have feedback / blistering criticism, or just want to say hello, don't hesitate to reach out.
                  </p>
                </div>

                <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                  <h2 className="text-xl sm:text-2xl font-semibold mb-4">Contact Information</h2>
                  <p className="text-base mb-2">
                    <span className="font-semibold">Email:</span> 
                    <a href="mailto:sanlorenzovc0722@gmail.com" className="text-blue-500 hover:text-blue-700 ml-2">
                      sanlorenzovc0722@gmail.com
                    </a>
                  </p>
                  <p className="text-base">
                    <span className="font-semibold">Twitter:</span> 
                    <a href="https://twitter.com/borderless" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700 ml-2">
                      @borderless
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
