import { 
  searchCompanies, 
  searchByTicker, 
  searchByIndustry, 
  searchByName, 
} from '../api/searchUtils';

export const handleSearch = async (
  searchValue,
  geographyFilter,
  setIsLoading,
  setHasSearched,
  setKeywords,
  setResults,
  setError,
  translations,
  language,
  searchType
) => {
  const query = typeof searchValue === 'string' ? searchValue : searchValue.target.value;

  setIsLoading(true);
  setHasSearched(true);
  console.log(geographyFilter);
  try {
    let searchResults;
    switch (searchType) {
      case 'ticker':
        searchResults = await searchByTicker(query, geographyFilter);
        break;
      case 'industries':
        searchResults = await searchByIndustry(query, geographyFilter);
        break;
      case 'name':
        searchResults = await searchByName(query, geographyFilter);
        break;
      default:
        searchResults = await searchCompanies(query, geographyFilter);
    }
    
    setResults(searchResults || []); // Ensure results is always an array
    setKeywords([]); // Reset keywords as they're not being used
  } catch (err) {
    console.error('Search error:', err);
    setError(translations[language].error);
    setResults([]); // Set results to an empty array in case of error
    setKeywords([]); // Reset keywords in case of error
  } finally {
    setIsLoading(false);
  }
};
