import React, { useState, useEffect, useCallback, useMemo } from 'react';

const InfiniteScroll = ({ searchType, onSuggestionClick, handleSearchWrapper, setQuery, geographyFilter }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const generalSuggestions = useMemo(() => [
    "💉 Glucose Monitoring Solutions", "🤖 Conversational AI Platforms", "🌿 Vertical Farming Systems",
    "🔗 Decentralized Finance (DeFi)", "📡 Low Earth Orbit Satellites", "🚗 Hydrogen Fuel Cells",
    "☁️ Edge Computing Solutions", "🔒 Zero Trust Security", "🧬 Synthetic Biology",
    "🚀 Reusable Launch Vehicles"
  ], []);

  const nameSuggestions = useMemo(() => ({
    global: [
      "🍎 Apple", "🪟 Microsoft", "📦 Amazon", "🔤 Alphabet",
      "👥 Facebook", "⚡ Tesla", "🎮 NVIDIA", "🏦 JPMorgan Chase",
      "💊 Johnson & Johnson", "💳 Visa"
    ],
    jp: [
      "🚗 Toyota", "🏢 SoftBank", "🛍️ Fast Retailing", "🏦 Mitsubishi UFJ",
      "💊 Takeda Pharmaceutical", "🎮 Sony", "🚂 East Japan Railway", "🏭 Keyence",
      "🚙 Honda", "🛒 Seven & i Holdings"
    ],
    kr: [
      "🔬 Samsung Electronics", "🚗 Hyundai Motor", "🏦 KB Financial Group", "💄 LG H&H",
      "🎮 NCSOFT", "🏭 POSCO", "💻 SK Hynix", "🛒 Lotte Shopping",
      "🏢 Naver", "📱 Kakao"
    ]
  }), []);

  const tickerSuggestions = useMemo(() => ({
    global: [
      "🍎 AAPL", "🪟 MSFT", "📦 AMZN", "🔤 GOOGL", "👥 FB", "⚡ TSLA", "🎮 NVDA", "🏦 JPM", "💊 JNJ", "💳 V",
      "🛒 WMT", "🏦 BAC", "🧼 PG", "🏰 DIS", "🎨 ADBE", "☁️ CRM", "🎬 NFLX", "📺 CMCSA", "🥤 PEP", "💻 INTC"
    ],
    jp: [
      "🚗 7203", "🏢 9984", "🛍️ 9983", "🏦 8306", "💊 4502", "🎮 6758", "🚂 9020", "🏭 6861", "🚙 7267", "🛒 3382"
    ],
    kr: [
      "🔬 005930", "🚗 005380", "🏦 105560", "💄 051900", "🎮 036570", "🏭 005490", "💻 000660", "🛒 023530", "🏢 035420", "📱 035720"
    ]
  }), []);

  const getSuggestions = useCallback(() => {
    switch (searchType) {
      case 'name':
        return nameSuggestions[geographyFilter] || nameSuggestions.global;
      case 'ticker':
        return tickerSuggestions[geographyFilter] || tickerSuggestions.global;
      case 'industries':
        return [];
      default:
        return generalSuggestions;
    }
  }, [searchType, geographyFilter, generalSuggestions, nameSuggestions, tickerSuggestions]);

  const suggestions = getSuggestions();

  useEffect(() => {
    if (suggestions.length === 0) return;

    const scrollInterval = setInterval(() => {
      setScrollPosition((prevPosition) => 
        (prevPosition + 1) % (suggestions.length * 150)
      );
    }, 50);

    return () => clearInterval(scrollInterval);
  }, [suggestions]);

  if (suggestions.length === 0) return null;

  const handleClick = (suggestion) => {
    const searchTerm = suggestion.split(' ').slice(1).join(' ');
    if (onSuggestionClick) {
      onSuggestionClick(searchTerm);
    }
    handleSearchWrapper(searchTerm);
    if (setQuery) {
      setQuery(searchTerm);
    }
  };

  return (
    <div className="mt-2 overflow-hidden h-12">
      <div 
        className="whitespace-nowrap transition-all duration-300 ease-linear"
        style={{ transform: `translateX(-${scrollPosition}px)` }}
      >
        {Array(100).fill(suggestions).flat().map((suggestion, index) => (
          <button
            key={index}
            onClick={() => handleClick(suggestion)}
            className="inline-block mx-2 px-4 py-2 bg-gray-200 text-gray-800 rounded-lg cursor-pointer hover:bg-blue-200 transition-colors duration-200"
          >
            {suggestion}
          </button>
        ))}
      </div>
    </div>
  );
};

export default InfiniteScroll;
