import React, { useState } from 'react';

const DiscoverPage = ({ setQuery, handleSearchWrapper }) => {
  const [selectedTab, setSelectedTab] = useState('Deep Tech');

  const trends = {
    'Deep Tech': [
      { id: 1, image: "waymo.jpg", title: "Self-Driving Car Advancements", description: "Major automakers and tech companies are making significant strides in autonomous vehicle technology.", keywords: ["Self-Driving Cars", "LIDAR", "Autonomous Vehicles"] },
      { id: 2, image: "solar.jpg", title: "Renewable Energy Efficiency Growth", description: "Significant advancements in solar technology are driving increased efficiency and adoption of renewable energy sources.", keywords: ["Solar Power", "Wind Power", "Renewable Energy"] },
      { id: 3, image: "electricity.jpg", title: "Electric Battery Advancements", description: "Breakthroughs in battery technology are improving energy density and charging speeds for electric vehicles and grid storage.", keywords: ["Solid State Batteries", "Energy Storage Solutions", "Electric Vehicle Batteries"] },
      { id: 4, image: "dna.jpg", title: "CRISPR Gene Editing", description: "Clinical trials using CRISPR technology are showing potential for treating genetic disorders.", keywords: ["CRISPR", "Gene Editing", "Rare Diseases"] }
    ],
    'Defense': [
      { id: 5, image: "orbital.jpg", title: "Orbital Launch System Advancements", description: "Significant progress in reusable rocket technology and innovative launch methods are revolutionizing space access.", keywords: ["Orbital Launch", "Reusable Rockets", "Satellites"] },
      { id: 6, image: "drones.jpg", title: "Military Drone Advancements", description: "Development of more sophisticated unmanned aerial vehicles is enhancing reconnaissance capabilities.", keywords: ["Unmanned Aerial Vehicles", "Military Drones", "Counter-UAV Systems"] },
      { id: 7, image: "usm.jpg", title: "Global Remilitarization", description: "Increased defense spending and modernization initiatives in countries around the world are reshaping global military dynamics.", keywords: ["Military Shipbuilding", "Weapons Systems", "Offensive and Defensive Capabilities"] },
      { id: 8, image: "cyber.jpg", title: "Advanced Cyber Warfare Capabilities", description: "Nations are developing sophisticated cyber weapons and defense systems to protect critical infrastructure and gain strategic advantages.", keywords: ["Cyber Warfare", "Cybersecurity", "National Defense"] }
    ],
    'Finance': [
      { id: 9, image: "defi.jpg", title: "Blockchain in Finance", description: "Financial institutions are increasingly adopting blockchain for secure and efficient transactions.", keywords: ["Blockchain", "Cryptocurrency", "Bitcoin Mining"] },
      { id: 10, image: "handshake.jpg", title: "Private Equity and Credit Growth", description: "Private equity and credit firms are expanding their reach, targeting diverse sectors and exploring new investment strategies.", keywords: ["Private Equity", "Private Credit", "Alternative Assets"] },
      { id: 11, image: "port.jpg", title: "Infrastructure Investment Boom", description: "Governments and private investors are increasing funding for large-scale infrastructure projects to stimulate economic growth and modernize aging systems.", keywords: ["Infrastructure", "Public-Private Partnerships", "Urban Development"] },
      { id: 12, image: "structure.jpg", title: "Asset-Backed Finance Innovation", description: "New structures in asset-backed securities are providing innovative financing solutions across various industries.", keywords: ["Asset-Backed Finance", "Securitization", "Structured Finance"] }
    ]
  };

  return (
    <div className="mt-8 sm:mt-4 space-y-6 w-full">
      <NavBar />
      <TabBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <div className="bg-white rounded-2xl p-4 shadow-lg">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {trends[selectedTab] && trends[selectedTab].map((trend) => (
            <ContentCard key={trend.id} {...trend} setQuery={setQuery} handleSearchWrapper={handleSearchWrapper} />
          ))}
        </div>
      </div>
    </div>
  );
};

const NavBar = () => (
  <div className="flex items-center mb-4">
    <h2 className="text-3xl font-bold">Discover Ideas</h2>
  </div>
);

const TabBar = ({ selectedTab, setSelectedTab }) => {
  const tabs = [
    { name: 'Deep Tech', emoji: '🧠' },
    { name: 'Defense', emoji: '🛡️' },
    { name: 'Finance', emoji: '💰' }
  ];

  return (
    <div className="flex space-x-4 mb-4 overflow-x-auto">
      {tabs.map((tab) => (
        <button
          key={tab.name}
          className={`px-4 py-2 rounded-lg ${selectedTab === tab.name ? 'bg-blue-400 text-white' : 'bg-gray-200'} flex items-center`}
          onClick={() => setSelectedTab(tab.name)}
        >
          <span className="mr-2">{tab.emoji}</span>
          {tab.name}
        </button>
      ))}
    </div>
  );
};

const ContentCard = ({ image, title, description, keywords, setQuery, handleSearchWrapper }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300 ease-in-out cursor-pointer flex flex-col">
    <div className="h-48 bg-cover bg-center" style={{ backgroundImage: `url('/images/${image}')` }}></div>
    <div className="p-4 flex-grow flex flex-col">
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600 mb-4">{description}</p>
      <div className="mt-auto flex flex-wrap">
        {keywords.map((keyword, index) => (
          <span 
            key={index} 
            className="bg-gray-200 text-sm rounded-md px-3 py-1 m-1 cursor-pointer hover:bg-blue-400 hover:text-white transition-colors duration-300"
            onClick={() => {
              setQuery(keyword);
              handleSearchWrapper(keyword);
            }}
          >
            {keyword}
          </span>
        ))}
      </div>
    </div>
  </div>
);

export default DiscoverPage;
