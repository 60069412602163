import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CompanySearch from './CompanySearch';
import Mission from './components/Mission';
import Contact from './components/Contact';
import { Analytics } from "@vercel/analytics/react"

function App() {
  useEffect(() => {
    document.title = "Project Borderless";
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<CompanySearch />} />
        <Route path="/mission" element={<Mission />} />
        <Route path="/contact" element={<Contact />} />
        {/* Add other routes as needed */}
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;