import React, { useState, useMemo, useRef, useEffect } from 'react';
import axios from 'axios';

export default function CompanyTable({ results, handleCompanyClick, industriesLabel, companyNameLabel, descriptionLabel, tickerLabel, geographyLabel }) {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [industryFilter, setIndustryFilter] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [oneYearPerformance, setOneYearPerformance] = useState({});
  const filterRef = useRef(null);

  const sortedResults = useMemo(() => {
    let sortableResults = [...results];
    if (sortConfig.key !== null) {
      sortableResults.sort((a, b) => {
        if (sortConfig.key === 'lfy_revenue') {
          const aValue = parseFloat(a[sortConfig.key]) || 0;
          const bValue = parseFloat(b[sortConfig.key]) || 0;
          return sortConfig.direction === 'ascending' ? aValue - bValue : bValue - aValue;
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableResults.filter(company => 
      industryFilter.length === 0 || industryFilter.some(industry => company.industries.includes(industry))
    ).slice(0, 30); // Limit to 30 results
  }, [results, sortConfig, industryFilter]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const toggleIndustryFilter = (industry) => {
    setIndustryFilter(industry === '' ? [] : [industry]);
  };

  const allIndustries = useMemo(() => 
    [...new Set(results.flatMap(company => company.industries))],
    [results]
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setIsFilterOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchOneYearPerformance = async (ticker, geography) => {
      try {
        const apiKey = "5VsF080UidTh1nQomwkcgnQjxIvkWktP";
        let formattedTicker = ticker;
        
        if (geography === 'Japan') {
          formattedTicker = `${ticker}.T`;
        } else if (geography === 'Korea') {
          formattedTicker = ticker.padStart(6, '0').toString();
          formattedTicker = `${formattedTicker}.KS`;
        }

        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        const fromDate = oneYearAgo.toISOString().split('T')[0];
        const toDate = new Date().toISOString().split('T')[0];

        const response = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${formattedTicker}?from=${fromDate}&to=${toDate}&apikey=${apiKey}`);
        
        if (response.data && response.data.historical && response.data.historical.length > 0) {
          const oldestPrice = response.data.historical[response.data.historical.length - 1].close;
          const latestPrice = response.data.historical[0].close;
          const performance = ((latestPrice - oldestPrice) / oldestPrice) * 100;
          setOneYearPerformance(prev => ({...prev, [ticker]: performance}));
        }
      } catch (error) {
        console.error('Error fetching one year performance:', error);
      }
    };

    sortedResults.forEach(company => {
      if (company.ticker) {
        fetchOneYearPerformance(company.ticker, company.geography);
      }
    });
  }, [sortedResults]);

  const getFlagImage = (country) => {
    const countryToFlag = {
      'United States': 'https://flagcdn.com/w40/us.png',
      'Korea': 'https://flagcdn.com/w40/kr.png',
      'Japan': 'https://flagcdn.com/w40/jp.png',
      // Add more countries and their flag image paths as needed
    };
    return countryToFlag[country] || null;
  };

  if (results.length === 0) return null;

  const industries = [
    "🚀 Aerospace & Defense", "🌾 Agriculture", "✈️ Airlines", "💼 Asset Management",
    "🚗 Automobiles", "🏦 Banks", "💊 Biopharmaceuticals", "🧪 Chemicals",
    "🏗️ Construction", "🛍️ Consumer Discretionary", "🖥️ Consumer Electronics",
    "🥫 Consumer Staples", "🛒 Consumer Retail", "🛒 E-Commerce", "🎓 Education",
    "💳 Fintech", "🏥 Health Insurance", "🏥 Hospitals", "🏥 Life Insurance",
    "⚙️ Machinery", "🎬 Media and Entertainment", "🩺 Medical Devices",
    "⛏️ Metals & Mining", "🛢️ Oil & Gas", "👔 Professional Services",
    "🏠 Property & Casualty Insurance", "🏨 Real Estate & Gaming & Hospitality",
    "🏢 REITs", "🍽️ Restaurants", "🖥️ Semiconductors", "📱 Social Media", "💻 Software",
    "⚡ Utilities", "📡 Telecommunications", "🚚 Transportation & Logistics"
  ];

  return (
    <div className="mt-8 sm:mt-4 space-y-6">
      <h2 className="text-2xl font-bold mb-4 text-left">Relevant Companies</h2>
      <div className="bg-white rounded-2xl p-6 shadow-lg w-full">
        <div className="mb-4 flex items-center">
          <label htmlFor="industryFilter" className="text-sm font-medium text-gray-700 mr-2">Filter:</label>
          <select
            id="industryFilter"
            className="block w-48 pl-3 pr-10 py-1 text-sm border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
            onChange={(e) => toggleIndustryFilter(e.target.value)}
            value={industryFilter.length > 0 ? industryFilter[0] : ''}
          >
            <option value="">All Industries</option>
            {allIndustries.map((industry) => {
              const industryEmoji = industries.find(i => i.includes(industry))?.split(' ')[0] || '🏢';
              return (
                <option key={industry} value={industry}>{industryEmoji} {industry}</option>
              );
            })}
          </select>
        </div>
        <div className="overflow-y-auto max-h-[calc(100vh-300px)]">
          <div id="scrollableDiv" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sortedResults.map((company) => (
              <div key={company.id} className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300 ease-in-out cursor-pointer flex flex-col h-full" onClick={() => handleCompanyClick(company)}>
                <div className={`p-4 ${oneYearPerformance[company.ticker] > 0 ? 'bg-green-500' : 'bg-red-500'} text-white text-center`}>
                  <span className="text-2xl font-bold">
                    {oneYearPerformance[company.ticker] ? oneYearPerformance[company.ticker].toFixed(2) : 'N/A'}%
                  </span>
                  <span className="block text-sm">1Y Return</span>
                </div>
                <div className="p-4 flex-grow flex flex-col">
                  <h3 className="text-xl font-semibold mb-2">{company.company_name || 'N/A'}</h3>
                  <div className="flex items-center mb-2">
                    {getFlagImage(company.geography) ? (
                      <img src={getFlagImage(company.geography)} alt={company.geography} className="w-6 h-4 mr-2" />
                    ) : null}
                    <span className="text-sm">{company.geography || 'N/A'}</span>
                  </div>
                  <div className="mb-2">
                    <span className="font-medium">Revenue: </span>
                    <span>
                      {company.lfy_revenue 
                        ? (parseFloat(company.lfy_revenue) < 1 
                          ? '<$1m' 
                          : `$${Number(Math.round(parseFloat(company.lfy_revenue))).toLocaleString()}m`)
                        : 'N/A'}
                    </span>
                  </div>
                  <div className="mb-2">
                    <span className="font-medium">Industries: </span>
                    <span>{company.industries || 'N/A'}</span>
                  </div>
                  <div className="mt-4 text-sm text-gray-600 flex-grow overflow-y-auto">
                    {company.description || 'No description available'}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
