import React, { useState, useEffect, useRef } from 'react';
import { renderProductsAndServices } from '../utils/renderUtils';
import StockPriceGraph from './StockPriceGraph';
import axios from 'axios';

export default function CompanyPopup({ company, onClose, typesenseClient }) {
  const [activeTab, setActiveTab] = useState('description');
  const [selectedText, setSelectedText] = useState('');
  const [showChatGPTSearch, setShowChatGPTSearch] = useState(false);
  const [searchPosition, setSearchPosition] = useState({ top: 0, left: 0 });
  const searchRef = useRef(null);
  const [currentPrice, setCurrentPrice] = useState(null);
  const [oneYearPerformance, setOneYearPerformance] = useState(null);
  const [availableTickers, setAvailableTickers] = useState([]);
  const [peRatio, setPeRatio] = useState(null);
  const [latestYearRevenue, setLatestYearRevenue] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [onClose]);

  useEffect(() => {
    const handleTextSelection = () => {
      const selection = window.getSelection();
      const selectedText = selection.toString().trim();
      if (selectedText) {
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        setSelectedText(selectedText);
        setSearchPosition({
          top: rect.top - 30,
          left: rect.left
        });
      }
    };

    document.addEventListener('mouseup', handleTextSelection);

    return () => {
      document.removeEventListener('mouseup', handleTextSelection);
    };
  }, []);

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const apiKey = "5VsF080UidTh1nQomwkcgnQjxIvkWktP";
        let formattedTicker = company.ticker;
        
        if (company.geography === 'Japan') {
          formattedTicker = `${company.ticker}.T`;
        } else if (company.geography === 'Korea') {
          formattedTicker = company.ticker.padStart(6, '0').toString();
          formattedTicker = `${formattedTicker}.KS`;
        }
        
        const quoteResponse = await axios.get(`https://financialmodelingprep.com/api/v3/quote/${formattedTicker}?apikey=${apiKey}`);
        if (quoteResponse.data && quoteResponse.data.length > 0) {
          setCurrentPrice(quoteResponse.data[0].price);
          setPeRatio(quoteResponse.data[0].pe);
        }

        const incomeStatementResponse = await axios.get(`https://financialmodelingprep.com/api/v3/income-statement/${formattedTicker}?limit=1&apikey=${apiKey}`);
        if (incomeStatementResponse.data && incomeStatementResponse.data.length > 0) {
          setLatestYearRevenue(incomeStatementResponse.data[0].revenue);
        }

        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        const fromDate = oneYearAgo.toISOString().split('T')[0];
        const toDate = new Date().toISOString().split('T')[0];

        const historicalResponse = await axios.get(`https://financialmodelingprep.com/api/v3/historical-price-full/${formattedTicker}?from=${fromDate}&to=${toDate}&apikey=${apiKey}`);
        if (historicalResponse.data && historicalResponse.data.historical && historicalResponse.data.historical.length > 0) {
          const oldestPrice = historicalResponse.data.historical[historicalResponse.data.historical.length - 1].close;
          const latestPrice = historicalResponse.data.historical[0].close;
          const performance = ((latestPrice - oldestPrice) / oldestPrice) * 100;
          setOneYearPerformance(performance);
        }
      } catch (error) {
        console.error('Error fetching stock data:', error);
      }
    };

    fetchStockData();
    setAvailableTickers(['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'FB']);
  }, [company.ticker, company.geography]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'description':
        return (
          <>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <h3 className="font-bold text-xl mb-2">Description</h3>
              <p>{company.description || 'No description available'}</p>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg mb-6">
              <h3 className="font-bold text-xl mb-2">Key Stats</h3>
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 border">Metric</th>
                      <th className="px-4 py-2 border text-center w-1/4">
                        {company.company_name}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="px-4 py-2 border font-semibold">Current Trading Price</td>
                      <td className="px-4 py-2 border text-center">
                        {currentPrice 
                          ? (company.geography === 'Japan' 
                            ? `¥${Math.round(currentPrice).toLocaleString()}` 
                            : company.geography === 'Korea'
                            ? `₩${Math.round(currentPrice).toLocaleString()}`
                            : `$${Math.round(currentPrice).toLocaleString()}`)
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border font-semibold">Latest Year Revenue</td>
                      <td className="px-4 py-2 border text-center">
                        {latestYearRevenue 
                          ? (latestYearRevenue < 1000000 
                            ? (company.geography === 'Japan' ? '<¥1mm' : company.geography === 'Korea' ? '<₩1mm' : '<$1mm')
                            : (company.geography === 'Japan' 
                              ? `¥${(latestYearRevenue / 1000000).toFixed(0).toLocaleString()}mm`
                              : company.geography === 'Korea'
                              ? `₩${(latestYearRevenue / 1000000).toFixed(0).toLocaleString()}mm`
                              : `$${(latestYearRevenue / 1000000).toFixed(0).toLocaleString()}mm`))
                          : 'N/A'}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border font-semibold">1-Year Price Performance</td>
                      <td className="px-4 py-2 border text-center">{oneYearPerformance ? oneYearPerformance.toFixed(2) + '%' : 'N/A'}</td>
                    </tr>
                    <tr>
                      <td className="px-4 py-2 border font-semibold">P/E Ratio</td>
                      <td className="px-4 py-2 border text-center">{peRatio ? peRatio.toFixed(2) : 'N/A'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="font-bold text-xl mb-2">Products and Services</h3>
              {renderProductsAndServices(company.products)}
            </div>
          </>
        );
      case 'stock':
        return (
          <div className="bg-gray-100 p-4 rounded-lg h-full overflow-auto">
            <h3 className="font-bold text-xl mb-2">Indexed Stock Price Performance (Zero Index)</h3>
            <StockPriceGraph company={company} />
          </div>
        );
      default:
        return null;
    }
  };

  const renderJSONContent = (json) => {
    if (typeof json === 'string') {
      return <p>{json}</p>;
    } else if (typeof json === 'object') {
      return (
        <ul>
          {Object.entries(json).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {renderJSONContent(value)}
            </li>
          ))}
        </ul>
      );
    }
    return null;
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-lg p-4 sm:p-8 w-full max-w-6xl h-[90vh] sm:h-[80vh] flex flex-col relative">
        <button 
          onClick={onClose}
          className="absolute top-2 right-2 bg-gray-200 text-gray-600 px-3 py-1 rounded-full hover:bg-gray-300"
        >
          ×
        </button>
        <div className="flex items-center mb-4 sm:mb-6">
          <div className="flex items-center">
            <img 
              src={`https://flagcdn.com/w40/${company.geography === 'United States' ? 'us' : company.geography === 'Korea' ? 'kr' : company.geography === 'Japan' ? 'jp' : company.geography.toLowerCase()}.png`} 
              alt={`${company.geography} flag`}
              className="w-8 h-5 sm:w-10 sm:h-6 object-cover"
            />
            <h2 className="text-xl sm:text-3xl font-bold ml-2 sm:ml-4">{company.company_name}</h2>
          </div>
        </div>
        
        <div className="flex mb-4 sm:mb-6 relative">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="sm:hidden bg-blue-500 text-white px-4 py-2 rounded"
          >
            Menu
          </button>
          <div className={`${isMenuOpen ? 'flex' : 'hidden'} sm:flex flex-col sm:flex-row absolute sm:static top-full left-0 bg-white sm:bg-transparent z-10 w-full sm:w-auto`}>
            <button
              onClick={() => {setActiveTab('description'); setIsMenuOpen(false);}}
              className={`mb-2 sm:mb-0 sm:mr-2 px-4 py-2 rounded ${activeTab === 'description' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              Description
            </button>
            <button
              onClick={() => {setActiveTab('stock'); setIsMenuOpen(false);}}
              className={`mb-2 sm:mb-0 sm:mr-2 px-4 py-2 rounded ${activeTab === 'stock' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              Stock Performance
            </button>
          </div>
        </div>
        
        <div className="flex-grow overflow-hidden">
          <div className="h-full overflow-auto">
            {renderTabContent()}
          </div>
        </div>
      </div>
    </div>
  );
}