import React from 'react';
import { translations } from '../translations';
import { Link } from 'react-router-dom';

const Mission = () => {
  const t = (key) => translations.en[key]; // Assuming English as default language

  return (
    <div className="min-h-screen bg-black flex flex-col" style={{ fontFamily: "'Poppins', sans-serif" }}>
      {/* Header Component */}
      <div className="bg-blue-400 text-white py-2 text-center cursor-pointer">
        <a href="/mission" className="hover:underline text-sm sm:text-base">
          Stockshop is in public beta mode. Click here for our mission and our roadmap for the next year.
        </a>
      </div>

      <div className="bg-black">
        <header className="py-2 container mx-auto px-4">
          <div className="flex items-center justify-between">
            <h1 className="text-xl sm:text-2xl text-white">stockshop 🛍️</h1>
            <nav>
              <ul className="flex space-x-6">
                <li><a href="/" className="text-sm sm:text-base text-white">{t('home')}</a></li>
                <li><Link to="/mission" className="text-sm sm:text-base text-white">{t('mission')}</Link></li>
                <li><a href="/contact" className="text-sm sm:text-base text-white">{t('contact')}</a></li>
              </ul>
            </nav>
          </div>
        </header>

        <div className="container mx-auto px-4 py-1">
          <h1 className="text-3xl sm:text-4xl font-bold text-center mb-1 text-white">{t('mission.title')}</h1>
        </div>
      </div>

      <div className="flex-grow py-4 flex flex-col justify-start sm:py-8">
        <div className="container mx-auto px-4">
          <div className="relative px-4 py-6 bg-black sm:rounded-3xl sm:p-8">
            <div className="max-w-9xl mx-auto">
              <div className="flex flex-col space-y-8">
                <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                  <h2 className="text-xl sm:text-2xl font-semibold mb-4">I. Our Mission</h2>
                  <p className="text-base mb-4">
                    {t('mission.missionDescription')}
                  </p>
                  <p className="text-base mb-4">
                  The future of finance is borderless, and our mission is to empower investors with in-depth qualitative information about companies around the world.
                  </p>
                  <p className="text-base mb-4">
                  We view the advent of ChatGPT as a Berlin Wall moment for international investing. Mainstream media might have you believe that globalization is coming to an end, but we couldn't disagree more: 
                  </p>
                  <ul className="list-disc pl-5 mb-4">
                    <li className="mb-2">The latest LLMs are already as good as - if not better than - human translators, making it inevitable that the cost of translation will eventually converge to zero. When that happens, language barriers will no longer present an obstacle to savvy investors, who will be able to seamlessly perform in-depth analysis on investments from all around the world.</li>
                    <li className="mb-2">Fierce competition is squeezing out alpha in the US markets, leading investors to increasingly look for opportunities in either small, illiquid stocks or foreign markets. If you think about how many kids graduate from Wharton every year wanting to be the next Warren Buffett...we don't see this trend slowing down anytime soon.</li>
                    <li className="mb-2">International markets are slowly beginning to adopt shareholder-friendly policies, with activist investing and take-privates becoming increasingly popular in hitherto challenging markets like Japan and Korea.</li>
                    <li className="mb-2">Emerging markets are the only regions where we will continue to see a demographic boost to economic growth. If history is any guide, healthy demographics (combined, of course, with reasonably sound institutions) are just about the only reliable leading indicator of future growth in both economic and financial power.</li>
                  </ul>
                  <p className="text-base mb-4">
                  All in all, emerging markets are not only becoming more attractive but also more accessible to enterprising investors, and we think this is a good trend to bet on.
                  </p>
                </div>

                <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                  <h2 className="text-xl sm:text-2xl font-semibold mb-4">II. The Road Ahead</h2>
                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg sm:text-xl font-medium">Q3 2024</h3>
                      <ul className="list-disc list-inside text-base">
                        <li>Add Argentina, Colombia, Malaysia, and Mexico to our list of available markets (IN PROGRESS)</li>
                        <li>Integrate earnings transcripts and more in-depth qualitative data (IN PROGRESS)</li>
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-lg sm:text-xl font-medium">Q4 2024</h3>
                      <ul className="list-disc list-inside text-base">
                        <li>Integrate more quantitative screening functionality (e.g. filtering based on ratios, historical performance, etc.)</li>
                        <li>Expand coverage to additional emerging markets</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="bg-white p-4 sm:p-6 rounded-lg shadow-md">
                  <h2 className="text-xl sm:text-2xl font-semibold mb-4">III. Who We Are</h2>
                  <p className="text-base">
                    We're a motley crew of former international students working at top banks and funds around the world. Get in touch / feel free to provide feedback at: <a href="mailto:sanlorenzovc0722@gmail.com" className="text-blue-500 hover:text-blue-700">sanlorenzovc0722@gmail.com</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
