// Imports
import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from './components/SearchBar';
import CompanyTable from './components/CompanyTable';
import CompanyPopup from './components/CompanyPopup';
import { translations } from './translations';
import { parseQuery, handleSearch } from './utils/searchFunctions';
import DiscoverPage from './components/GlobalHeadlines';
import InfiniteScroll from './components/InfiniteScroll';
import Disclaimer from './components/Disclaimer';

// Emoji bank
const emojiBank = {
  globe: '🌎',
  chart: '📈',
  gem: '💎',
  explorer: '🔍',
  lightning: '⚡',
  idea: '💡',
  comparison: '📊',
};

// Main Component
export default function CompanySearch() {
  // State declarations
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [keywords, setKeywords] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const [language, setLanguage] = useState(urlParams.get('lang') || 'en');
  const [searchType, setSearchType] = useState('default');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const suggestionsRef = useRef(null);
  const [geographyFilter, setGeographyFilter] = useState('global');
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  // TODO: Consider moving this effect to a separate component or utility function
  // Event Handlers
  const handleGeographyFilterChange = (filter) => {
    setGeographyFilter(filter);
  };

  // TODO: Consider moving this effect to a separate component
  useEffect(() => {
    function handleClickOutside(event) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // TODO: Consider moving these handlers to a separate file
  const handleSearchWrapper = async (searchValue) => {
    await handleSearch(
      searchValue,
      geographyFilter,
      setIsLoading,
      setHasSearched,
      setKeywords,
      setResults,
      setError,
      translations,
      language,
      searchType // Pass the current searchType to handleSearch
    );
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const handleClosePopup = () => {
    setSelectedCompany(null);
  };

  // TODO: Consider moving this effect to a separate component
  useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        handleClosePopup();
      }
    };

    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  const handleCompanySelection = (companies) => {
    setSelectedCompanies(companies);
  };

  const handleSearchTypeChange = (newSearchType) => {
    setSearchType(newSearchType);
    setQuery(''); // Clear the query when changing search type
  };

  const handleDisclaimerAgree = () => {
    setShowDisclaimer(false);
  };

  const IndustryDropdown = ({ selectedIndustry, onSelectIndustry }) => {
    const industries = [
      "🚀 Aerospace & Defense", "🌾 Agriculture", "✈️ Airlines", "💼 Asset Management",
      "🚗 Automobiles", "🏦 Banks", "💊 Biopharmaceuticals", "🧪 Chemicals",
      "🏗️ Construction", "🛍️ Consumer Discretionary", "🖥️ Consumer Electronics",
      "🥫 Consumer Staples", "🛒 Consumer Retail", "🛒 E-Commerce", "🎓 Education",
      "💳 Fintech", "🏥 Health Insurance", "🏥 Hospitals", "🏥 Life Insurance",
      "⚙️ Machinery", "🎬 Media and Entertainment", "🩺 Medical Devices",
      "⛏️ Metals & Mining", "🛢️ Oil & Gas", "👔 Professional Services",
      "🏠 Property & Casualty Insurance", "🏨 Real Estate & Gaming & Hospitality",
      "🏢 REITs", "🍽️ Restaurants", "🖥️ Semiconductors", "📱 Social Media", "💻 Software",
      "⚡ Utilities", "📡 Telecommunications", "🚚 Transportation & Logistics"
    ];

    return (
      <div className="relative inline-block w-full">
        <select
          value={selectedIndustry}
          onChange={(e) => onSelectIndustry(e.target.value)}
          className="appearance-none bg-white text-black font-semibold py-2 pl-3 pr-8 rounded-md cursor-pointer w-full"
        >
          <option value="">Select an Industry</option>
          {industries.map((industry) => (
            <option 
              key={industry} 
              value={industry.split(' ').slice(1).join(' ')} 
              className="bg-white text-black"
            >
              {industry}
            </option>
          ))}
        </select>
      </div>
    );
  };
  
  // TODO: Consider splitting this return statement into smaller components
  return (
    <div className="min-h-screen bg-white flex flex-col" style={{ fontFamily: "'Poppins', sans-serif" }}>
      {showDisclaimer && <Disclaimer onAgree={handleDisclaimerAgree} />}
      {/* Header Component */}
      <div className="bg-blue-400 text-white py-2 text-center cursor-pointer">
        <a href="/mission" className="hover:underline text-sm sm:text-base">
          Stockshop is in public beta mode. Click here for our mission and our roadmap for the next year.
        </a>
      </div>

      <div className="bg-cover bg-[center_45%]" style={{ backgroundImage: "url('/images/nyc2.jpg')" }}>
        <header className="py-2 container mx-auto px-4">
          <div className="flex items-center justify-between">
            <h1 className="text-xl sm:text-2xl text-white">stockshop 🛍️</h1>
            <nav>
              <ul className="flex space-x-6">
                <li><a href={`/?lang=${language}`} className="text-sm sm:text-base text-white">{translations[language].home}</a></li>
                <li><Link to={`/mission?lang=${language}`} className="text-sm sm:text-base text-white">{translations[language].mission}</Link></li>
                <li><a href={`/contact?lang=${language}`} className="text-sm sm:text-base text-white">{translations[language].contact}</a></li>
              </ul>
            </nav>
          </div>
        </header>
        
        <div className="container mx-auto px-4 py-12">
          <h2 className="text-2xl sm:text-3xl mb-8 font-semibold text-white">
            Find the perfect stock. One simple search.
          </h2>

          {/* Search Area */}
          <div className="bg-white bg-opacity-80 p-6 rounded-lg mb-8">
            <div className="mb-6 flex justify-between items-center">
              <div className="flex items-center">
                <div className="text-center mr-4">
                  <span className="font-semibold block mb-2 text-black">Search Type</span>
                  <div className="inline-flex items-center bg-white rounded-lg p-1 border border-black">
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${searchType === 'default' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold`}
                      onClick={() => handleSearchTypeChange('default')}
                    >
                      General
                    </button>
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${searchType === 'name' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold`}
                      onClick={() => handleSearchTypeChange('name')}
                    >
                      Name
                    </button>
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${searchType === 'ticker' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold`}
                      onClick={() => handleSearchTypeChange('ticker')}
                    >
                      Ticker
                    </button>
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${searchType === 'industries' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold`}
                      onClick={() => handleSearchTypeChange('industries')}
                    >
                      Industries
                    </button>
                  </div>
                </div>
                <div className="text-center ml-4">
                  <span className="font-semibold block mb-2 text-black">Geography</span>
                  <div className="inline-flex items-center bg-white rounded-lg p-1 border border-black">
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${geographyFilter === 'global' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold flex items-center`}
                      onClick={() => handleGeographyFilterChange('global')}
                    >
                      <span className="mr-2">🌎</span>
                      Global
                    </button>
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${geographyFilter === 'us' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold flex items-center`}
                      onClick={() => handleGeographyFilterChange('us')}
                    >
                      <img src="https://flagcdn.com/w40/us.png" alt="US Flag" className="w-6 h-4 mr-2" />
                      US
                    </button>
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${geographyFilter === 'kr' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold flex items-center`}
                      onClick={() => handleGeographyFilterChange('kr')}
                    >
                      <img src="https://flagcdn.com/w40/kr.png" alt="Korea Flag" className="w-6 h-4 mr-2" />
                      Korea
                    </button>
                    <button 
                      className={`px-3 py-1 rounded-md transition-all duration-300 ${geographyFilter === 'jp' ? 'bg-blue-400 text-white' : 'bg-white text-black'} font-semibold flex items-center`}
                      onClick={() => handleGeographyFilterChange('jp')}
                    >
                      <img src="https://flagcdn.com/w40/jp.png" alt="Japan Flag" class="w-6 h-4 mr-2" />
                      Japan
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {searchType === 'industries' ? (
              <IndustryDropdown
                selectedIndustry={query}
                onSelectIndustry={(industry) => {
                  setQuery(industry);
                  handleSearchWrapper(industry);
                }}
              />
            ) : (
              <SearchBar 
                query={query} 
                setQuery={setQuery}
                handleSearch={handleSearchWrapper} 
                placeholder={translations[language].searchPlaceholder}
                searchType={searchType}
              />
            )}
            <div className="mt-6">
              <InfiniteScroll searchType={searchType} handleSearchWrapper={handleSearchWrapper} setQuery={setQuery} geographyFilter={geographyFilter}/>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex-grow py-4 flex flex-col justify-start sm:py-8">
        <div className="container mx-auto px-4">
          <div className="relative px-4 py-6 bg-white sm:rounded-3xl sm:p-8">
            <div className="max-w-9xl mx-auto">
              {/* Main Content */}
              <div className="flex flex-col md:flex-row">
                {/* Left-hand side: Global Headlines */}
                {!hasSearched && !isLoading && (
                  <div className="w-full md:w-full pr-4">
                    <DiscoverPage setQuery={setQuery} handleSearchWrapper={handleSearchWrapper} hasSearched={hasSearched} />
                  </div>
                )}

                {/* Right-hand side: Company Table */}
                {hasSearched && !isLoading && (
                  <div className="w-full md:w-3/3 pl-2 flex flex-col justify-start">
                    <div className="h-full w-full">
                      <CompanyTable 
                        results={results} 
                        handleCompanyClick={handleCompanyClick} 
                        handleSelectedCompanies={handleCompanySelection}
                        industriesLabel={translations[language].industries}
                        companyNameLabel={translations[language].companyName}
                        descriptionLabel={translations[language].description}
                        websiteLabel={translations[language].website}
                        foundedLabel={translations[language].founded}
                        logoLabel={translations[language].logo}
                        tickerLabel={translations[language].ticker}
                        geographyLabel={translations[language].geography}
                        exchangeLabel={translations[language].exchange}
                      />
                      {!isLoading && results.length === 0 && (
                        <p className="mt-4 text-center">{translations[language].noResults}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>

              {/* Loading and Error Messages Component */}
              {isLoading && (
                <div className="mt-4 flex flex-col justify-center items-center">
                  <div className="relative w-24 h-24">
                    <div className="absolute top-0 left-0 w-full h-full border-4 border-blue-200 rounded-full animate-pulse"></div>
                    <div className="absolute top-0 left-0 w-full h-full border-t-4 border-blue-500 rounded-full animate-spin"></div>
                  </div>
                  <p className="mt-4 text-blue-500 font-semibold">Loading...</p>
                </div>
              )}
              {error && <p className="mt-4 text-red-500 text-center">{error}</p>}
            </div>
          </div>
        </div>
      </div>
      {selectedCompany && (
        <CompanyPopup
          company={selectedCompany}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
}
